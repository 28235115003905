
body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #fcfcfc;
}

.container {
max-width: 900px!important;
display: flex;
justify-content: space-between;
align-items: center;
height: 100%;
}



.navbar {
height: 63px;
background-color: #fef7e5;
position: relative;
}


.menu-icon {
  display: none;
  z-index:1000;
  padding-top: -5px;
}


.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding-top: 8px;
}

.nav-elements ul li:not(:last-child) {
margin-right: 50px;
}

.nav-elements ul a {
font-size: 16px;
font-weight: 600;
color: #2f234f;
text-decoration: none;
}

.nav-elements ul a.active {
color: #574c4c;
font-weight: 500;
position: relative;
}

.nav-elements ul a.active::after {
content: '';
position: absolute;
bottom: -4px;
left: 0;
width: 100%;
height: 2px;
background-color: #574c4c;
}

@media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
      margin-right: 30px;
    }
}

@media (max-width: 990px) {
    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 60px;
        background-color: #fef7e5;
        width: 0px;
        height: 300px;
        transition: all 0.3s ease-in;
        overflow: hidden;
    }

    .nav-elements.active {
        width: 150px;
        z-index: 1000;
    }

    .nav-elements.active > ul > li {
        text-align: left; 
        margin-right: 0px;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }
}