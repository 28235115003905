/* HERO SECTION */
.container-hero{
  background-color:#495E57 ;
  
}

.hero__header{
  font-size: 50px;
}

.container-wrapper-hero {
  background-color: #495E57;
}

.container-wrapper-hero p{
 display: flex;
 flex-wrap: wrap;
}

.mainp{
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
}

.row-hero{
  width: 75%; /* Adjust the width as desired */
  margin: 0 auto; /* Center the sections horizontally */
}

.row-hero h1{
  color: #F4CE14;
  font-family: 'Markazi Text';
}

.imageHero{
  width: 358px;
  height: 383px;
  display: flex;
  border-radius: 15px;
  text-align: right;
  position: absolute;
  margin-top: 30px;
}

/* Media query for smartphones */
@media (max-width: 990px) {
  .imageHero{
    position: relative;
    margin-bottom: 20px;
    z-index: 10;
  }
}

h1 {
  font-family: "Markazi Text", serif;
  font-size: 60px;
  font-weight: 700;
  color: #f4ce14;
  padding-top: 0.5em;
}

h2 {
  font-family: "Markazi Text", serif;
  font-size: 30px;
  color: #fff;

}

h3 {
  font-family: "Markazi Text", serif;
  font-size: 50px;
  color: #000;

  font-weight: bold;
  margin-top: 2rem;
}

@media (max-width: 778px) {
  .center-head{
    text-align: center;
  }
}


h4 {
  font-family: "Markazi Text", serif;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}

h5 {
  font-family: "Karla", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.container-hero p {
  font-family: "Karla", sans-serif;
  font-size: 20px;
  color: #fff;
  width: 350px;
}

.yellowBtn{
  background-color: #f4ce14;
  color: #000;
  font-family: "Karla", sans-serif;
  font-weight: bold;
  font-size: 18px;
  width: 13em;
  height: 3em;
  margin: 1em;
  border-radius: 2em;
  border: none;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
}


.yellowBtn:hover{
  background: #495E57;
  color: white;
}

.yellowBtn:disabled {
  background-color: gray;
  color: #fff;
}

.align-right {
  float: right;
}
/* END HERO SECTION */

/* HIGHLIGHTS */
.marginTop{
  margin-top: 7rem;
}
#button {
  background-color: #f4ce14;
  color: #000;
  font-family: "Karla", sans-serif;
  font-weight: 800;
  font-size: 18px;
  width: 12em;
  height: 3em;
  margin: 1em;
  border-radius: 2em;
  border: none;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
}

#button:hover {
  cursor: pointer;
}

.specials {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  
}

.specials-head {
  display: flex;
  justify-content: center;

}

@media (max-width: 778px) {
  .marginTop{
    margin-top: 0rem;
  }
  .specials {
    margin-top: 0em;
  }
  .specials-head {
    display: inline;
  }
}


.card-img-top {
  width: 95%;
  border-top-left-radius: 10%;
  border-top-right-radius: 10%;
  margin-bottom: -0.2em;
  box-shadow: 5px 10px 20px #ccc;
  margin-top: 8em;
}

.card {
  margin-top: -4em;
  margin-left: 1em;
  margin-right: 1em;

  border: none;
  background-color: transparent;
}

.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 3em;
  margin-top: 1em;

}

.card-body {
  font-family: "Karla", sans-serif;
  background-color: #f2efef;
  margin-right: 1em;
  padding: 1em;
  line-height: 1.8em;
  height: 250px;
  box-shadow: 5px 10px 20px #ccc;
  
}

.card:hover .card-img{
    transform: scale(1.1);
}

.card-img {
  transition: transform 0.3s ease;
}

@media (min-width: 200px) {
  .card-body {
    height: 300px;
  }
}

.card-title {
  font-family: "Markazi Text", serif;
  font-size: 35px;
  font-weight: 600;
}

.btn.btn-primary {
  font-family: "Karla", sans-serif;
  font-weight: 900;
  font-size: 18px;
  border: none;
}

.title {
  width: 30%;
  height: auto;
  margin-right: 8em;
}

.title-button {
  width: 50%;
  height: auto;
  margin-top: 10.5em;
  margin-left: 15em;
}
/* END HIGHLIGHTS */

/* TESTIMONIALS START */
.testimonials {
 display:flex;
 justify-content: center;
 flex-wrap: wrap;
 width:100%;
 height:auto;
 background-color: #495e57;
 text-align: center;
 padding-top: 1em;
}

.testimonial-header {
  font-family: "Markazi Text", serif;
  font-size: 60px;
  font-weight: 700;
  width: 100%;
  color: #f4ce14;
}

.review-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  align-items: center;
}

.review {
  width: 250px;
  height: 250px;
  background-color: #fff;
  margin: 2em;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}


.review p {
  font-family: "Karla", sans-serif;
}


@media (max-width: 1050px) {
  .review p {
    margin: 1em;
  }
}

.profile-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

#profile {
  width: 30%;
  border-radius: 50%;
  margin: 1em;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center!important;
  align-items: center;
}


#rating {
  width: 50%;
  display: block;
  margin: 10px auto;
}

.review{
  border-radius: 10px;
}

.review h2 {
  font-family: "Markazi Text", serif;
  font-size: 25px;
  color: #000;
  text-align: center;
  margin: 0.1em;
}
/* TESTIMONIALS END */

/* ABOUT START */

.image-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-about{
  width: 440px;
  margin-top: 5rem;
}

.marginBottomAbout{
  margin-bottom: 2rem;
}

/* FOOTER START */
.container-footer{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #495E57;
  padding-top: 2rem;
  padding-bottom: 2rem;

}

.container-wrapper-footer {
  margin-top: 20px;
  background-color: #495E57;
  flex-wrap: wrap;
}

.link-footer > a{
  color:white;
  text-decoration: none;
}

.logoWhite{
  margin-right: 2rem;
}

@media (max-width: 990px) {
  .container-footer {
    padding-left: 3rem;
    margin-top: 3rem;
  }
  .logoWhite{
    margin-right: 0rem;
  }
  h5{
    margin-top: 3rem;
  }
}

/* FOOTER END */


/* BOOKING FORM START */

.hero-section {
  
  background-color: #495E57;
  background-size: cover;
  background-position: center;
  height: 250px; /* Adjust the height as needed */
  display: block;
  justify-content: center;
  align-items: center;
  width: 100%;
}



.hero-content {
  text-align: center;
  color: #ffffff; /* Adjust the text color */
  position: relative;
  z-index: 1;
}


.form-Booking{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 7rem;
}

.formBooking{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 7rem;
}

.bookHeadline{
  margin: 0;
  padding-top: 90px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}


.Field input{
  display:block;
  margin-top: 5px;
  width: 100%;
}

.FieldError{
  font-size: 12px;
  color: red;
  margin-bottom: 0px;
}
/* END BOOKING FORM*/

/* ORDER SUCCESS START */

.orderSuccess{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 7rem;
}

/* MENU CARDS */

.section-cards__menu{
  max-width: 1240px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.card-menu{
  width: 200px;
  margin: 20px;
  box-shadow: 5px 10px 20px #ccc;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
}

.card-menu__img{
  height: 200px;
  width: auto;
  border-radius: 10px 10px 0 0;
  transition: transform 0.3s ease;

}

.card-menu > img:hover{
  transform: scale(1.1);
}

.card-menu__text > h1{
  font-size: 20px;
  color: #000;
  font-family:Arial, Helvetica, sans-serif;

}

.card-menu__text > h2{
  font-size: 10px;
  color: #000;
  font-family:Arial, Helvetica, sans-serif;
}

.card-menu__text{
  margin: 20px;
}

@media (max-width: 720px) {
  .card-menu {
    width: 400px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


/* START HIGHLIGHTS 2 */
  .card-menu__img{
    width: 100%;
    height: auto;
    object-fit: contain;
    align-self: flex-start;
  }

}

.cards__highlights2{
  display: flex;
  justify-content: center;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.card__highlights2{
  width: 280px;
  margin: 20px;
  box-shadow: 10px 10px 20px #ccc;
  border: none;
  border-radius: 10px;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.card__highlights2 > img {
  border-radius: 10px 10px 0 0;
  width: 100%;
  transition: transform 0.3s ease;
}

.card__highlights2 > img:hover {
  transform: scale(1.1);
}

.card__highlights2-text{
  margin: 20px;
}

.card__highlights2-text > h2{
  color: #000;
  font-weight: 600;
}

.week__specials{
  margin-top: 5rem!important;
}

/* END HIGHLIGHTS 2 */

/* START HERO 2 */

.hero2__container{
  background-color: #495E57;
}

.hero2__row{
  max-width: 1140px;
  display: flex;
  justify-content:center;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.hero2__column{
  width: 400px;
  margin: 20px;
}


.hero2__column > p{
  color: #fff;
}


.hero2__column-img > img{
  width: 250px;
  height: 350px;
  border-radius: 15px!important;
  margin-top: 15px;
  position: relative;
  bottom: -50px;
  overflow: hidden;
  transition: transform 0.3s ease;
  
}

.hero2__img_style:hover{
  transform: scale(1.1);
}

@media (max-width: 720px) {
  .hero2__column-img > img{
    position: relative;
    bottom: 0px;
  }

  .hero2__ButtonHero{
    text-align: center;
  }

}

@media (max-width: 689px) {
  .hero2__column-img > img{
    margin-top: -10px;
    margin-bottom: 20px;
  }
}

/* START ERROR 404  */

.error__container{
  margin-top: 15vh;
  margin-bottom: 15vh;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.error__row{
  display: flex;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
}

.error__img{
  width: 100%;
}

.error__p{
  text-align: center;
  font-family:Tahoma, sans-serif;
  font-weight: 100;
  font-size: 25px;
}

.error__div{
  max-width: 600px;
  text-align: center;
  position: relative;
  justify-content: center;
  
}


